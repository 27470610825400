{#if editing}
  <!-- FFG: if variable deutsch is set, set lang="de" -->
  {#if multiline}
    <textarea
    bind:value={storeToUse[field]}
    id={`evaluation-meta-edit-${field}`}
    lang="{deutsch ? 'de' : null}"
    ></textarea>
  {:else}
  <input
    type="text"
    bind:value={storeToUse[field]}
    id={`evaluation-meta-edit-${field}`}
    lang="{deutsch ? 'de' : null}"
  />
  {/if}
{:else}
  {#if storeToUse[field]}
    {#if deutsch}
    <span lang="de">{storeToUse[field]}</span>
    {:else if checkURL && isValidUrl(storeToUse[field])}
    <a target="_blank" rel="noopener noreferrer" href="{storeToUse[field]}">{storeToUse[field]}</a>
    {:else}
    {storeToUse[field]}
    {/if}
  {:else}
    <span class="no-result">{TRANSLATED.LABEL_NOT_PROVIDED}</span>
  {/if}
{/if}

<style>
  .no-result {
    font-weight: normal;
    font-style: italic;
    margin-bottom: 1em;
  }
  textarea {
    margin-bottom: 1em;
  }
</style>

<script>
  import { getContext } from 'svelte';
  const { scopeStore, summaryStore, translate } = getContext('app');  
  
  export let field;
  export let multiline = false;
  export let editing;
  export let store;
  export let deutsch = undefined;
  export let checkURL = undefined;

  $: storeToUse = getStore(store); 
  
  function getStore(store) {
    if (store === "scopeStore") {
      return $scopeStore
    }
    if (store === "summaryStore") {
      return $summaryStore
    }
  }

  function isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  $: TRANSLATED = {
    LABEL_NOT_PROVIDED: $translate('UI.REPORT.LABEL_NOT_PROVIDED'),
  };
</script>