<p class="your-report__description">
  {TRANSLATED.REPORTED_ON} 
  {totalEvaluated} 
  {TRANSLATED.REPORTED_ON_OF} 
  {criteria} WCAG {wcagVersion}
  {conformanceTarget}
  Success Criteria.
</p>

<script>
  import { getContext } from 'svelte';

  import assertions from '@app/stores/earl/assertionStore/index.js';
  import { CriteriaSelected } from '@app/stores/selectedCriteriaStore.js';
  import subjects, { TestSubjectTypes } from '@app/stores/earl/subjectStore/index.js';

  const { translate, scopeStore } = getContext('app');

  export let criteria = 0;

  $: wcagVersion = $scopeStore['WCAG_VERSION'];
  // FFG: overwrite conformanceTarget with 'AA', since this is always the case and $scopeStore['CONFORMANCE_TARGET'] has been changed
  //$: conformanceTarget = $scopeStore['CONFORMANCE_TARGET'];
  $: conformanceTarget = 'AA';

  $: TRANSLATED = {
    REPORTED_ON: $translate('UI.REPORT.REPORTED_ON'),
    REPORTED_ON_OF: $translate('UI.REPORT.REPORTED_ON_OF')
  };

  /* FFG: exclude EN Criteria from count
  $: totalEvaluated = $assertions.filter(assertion => 
   assertion.result.outcome.id !== "earl:untested" && assertion.subject.type.indexOf(TestSubjectTypes.WEBSITE) >= 0).length
  */
  $: totalEvaluated = $assertions.filter(assertion => 
   assertion.result.outcome.id !== "earl:untested" && assertion.subject.type.indexOf(TestSubjectTypes.WEBSITE) >= 0).filter(item => !$CriteriaSelected.filter(item => item.conformanceLevel == 'EN').map(item => item.num).includes(item.test.num)).length;

</script>
