import { writable } from 'svelte/store';

export const initialScopeStore = {
  ADDITIONAL_REQUIREMENTS: '',
  AS_BASELINE: '',
  CONFORMANCE_TARGET: 'AA',
  SITE_NAME: '',
  WCAG_VERSION: '2.1',
  WEBSITE_SCOPE: '',
  // FFG: additional evaluation fields
  APP_TYPE: '',
  REPORT_TYPE: '',
  STATEMENT_URL: '',
  STATEMENT_COMPLIANCE: ''
};

const scopeStore = writable({...initialScopeStore});

export default scopeStore;
