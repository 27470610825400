<Page title="{TRANSLATED.PAGE_TITLE}" pageid="ffg-audit-sample">
  <!-- FFG: remove Beta Import Feature
  <AuditorImport />
  -->
  <p>{@html TRANSLATED.INTRODUCTION}</p>
  <p>{@html TRANSLATED.INTRODUCTION_NOTE_1}</p>
  <p>{@html TRANSLATED.INTRODUCTION_NOTE_2}</p>
  <!--<ResourceLink href="https://www.w3.org/TR/WCAG-EM/#step4">{TRANSLATED.RESOURCE_LINK_NAME}</ResourceLink>-->
  <!-- FFG: only show EN Criteria if in-depth report is selected -->
  {#if $scopeStore['REPORT_TYPE'] == 'report:in-depth'}
  <FFG_Auditor />
  {/if}
</Page>

<script>
  import { getContext } from 'svelte';

  import FFG_Auditor from '@app/components/ui/Auditor/FFG_Auditor.svelte';
  import AuditorImport from '@app/components/ui/Auditor/AuditorImport.svelte';
  import ResourceLink from '@app/components/ui/ResourceLink.svelte';
  import Page from '@app/components/ui/Page.svelte';
  import assertions from '@app/stores/earl/assertionStore/index.js';

  const { translate, scopeStore } = getContext('app');

  $: TRANSLATED = {
    PAGE_TITLE: $translate('PAGES.FFG_AUDIT.TITLE'),
    INTRODUCTION: $translate('PAGES.FFG_AUDIT.INTRO'),
    INTRODUCTION_NOTE_1: $translate('PAGES.FFG_AUDIT.INTRO_NOTE_1'),
    INTRODUCTION_NOTE_2: $translate('PAGES.FFG_AUDIT.INTRO_NOTE_2'),
    RESOURCE_LINK_NAME: $translate('PAGES.FFG_AUDIT.RESOURCE_LINK_NAME')
  };

</script>
