<Field id="{id}" {...$$restProps}>
  <input
    id="{id}"
    type="text"
    on:keydown
    bind:value
    bind:this="{textInput}"
    lang="{deutsch ? 'de' : null}"
  /> <!-- FFG: if variable deutsch is set, set lang="de" -->
</Field>

<script>
  import { onMount } from 'svelte';
  import Field from './Field.svelte';

  export let id;
  export let value = '';
  export let autofocus = false;
  export let deutsch = undefined;

  let textInput;

  onMount(() => {
    if (autofocus) {
      textInput.focus();
    }
  });
</script>

<style>
  input {
    max-width: 40em;;
  }
</style>